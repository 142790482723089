/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


.mailing-list-popup {
    width: 500px;
    height: 400px;
    position: absolute;
    top: calc(97% - 400px);
    left: calc(97% - 500px);

    ion-content {
        --background: red;
    }
}


.es {
    background-image: url('assets/backgrounds/spanish-background.png');
    background: url('assets/backgrounds/spanish-background.png');

    ion-content {
        --background: none;
        background-image: url('assets/backgrounds/spanish-background.png');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
    }
}

.fr {
    background-image: url('assets/backgrounds/french-background.png');
    background: url('assets/backgrounds/french-background.png');

    ion-content {
        --background: none;
        background-image: url('assets/backgrounds/french-background.png');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
    }
}

.menu-button {
    --background: #131C1D;
    --color: white;
}

@media screen and (max-width: 768px){
    .es {
        .sign-up-outer-frame {
            background-image: url('assets/backgrounds/spanish-background.png');
            color: white;
        }
    }

    .fr {
        .sign-up-outer-frame {
            background-image: url('assets/backgrounds/french-background.png');
            color: white;
        }
    }
}